@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'TT Interphases Pro Trial';
	src: url('../public/fonts/webfonts/TT Interphases Pro Trial Thin.ttf') format('truetype');
	font-weight: 100; /* Thin */
	font-style: normal;
}

@font-face {
	font-family: 'TT Interphases Pro Trial';
	src: url('../public/fonts/webfonts/TT Interphases Pro Trial ExtraLight.ttf') format('truetype');
	font-weight: 200; /* ExtraLight */
	font-style: normal;
}

@font-face {
	font-family: 'TT Interphases Pro Trial';
	src: url('../public/fonts/webfonts/TT Interphases Pro Trial Light.ttf') format('truetype');
	font-weight: 300; /* Light */
	font-style: normal;
}

@font-face {
	font-family: 'TT Interphases Pro Trial';
	src: url('../public/fonts/webfonts/TT Interphases Pro Trial Regular.ttf') format('truetype');
	font-weight: 400; /* Regular */
	font-style: normal;
}

@font-face {
	font-family: 'TT Interphases Pro Trial';
	src: url('../public/fonts/webfonts/TT Interphases Pro Trial Medium.ttf') format('truetype');
	font-weight: 500; /* Medium */
	font-style: normal;
}

@font-face {
	font-family: 'TT Interphases Pro Trial';
	src: url('../public/fonts/webfonts/TT Interphases Pro Trial DemiBold.ttf') format('truetype');
	font-weight: 600; /* DemiBold */
	font-style: normal;
}

@font-face {
	font-family: 'TT Interphases Pro Trial';
	src: url('../public/fonts/webfonts/TT Interphases Pro Trial Bold.ttf') format('truetype');
	font-weight: 700; /* Bold */
	font-style: normal;
}

@font-face {
	font-family: 'TT Interphases Pro Trial';
	src: url('../public/fonts/webfonts/TT Interphases Pro Trial ExtraBold.ttf') format('truetype');
	font-weight: 800; /* ExtraBold */
	font-style: normal;
}

@font-face {
	font-family: 'TT Interphases Pro Trial';
	src: url('../public/fonts/webfonts/TT Interphases Pro Trial Black.ttf') format('truetype');
	font-weight: 900; /* Black */
	font-style: normal;
}

:root {
	--bold: #2b3f5a !important;
	--black: #2b3f5a !important;
	--brown: #6e7892 !important;
	--blue: #235dff !important;
	--yellow: #ffe984 !important;
	--light-brown: #f9f6f3 !important;
	--brown-light: rgba(110, 120, 146, 0.5) !important;
	--white: #ffffff !important;
	--red: #ff5749 !important;
	--green: #46cb5c !important;
	--toastify-color-info: #5254f1;
	--toastify-color-success: #46cb5c;
	--toastify-color-warning: #f1c40f;
	--toastify-color-error: #ff5749;
}
* {
	box-sizing: border-box;
	user-select: text;
	font-family: 'TT Interphases Pro Trial', sans-serif !important;
}

html {
	scroll-behavior: smooth;
}

body {
	background: #fff !important;
	overflow-y: scroll;
	margin: 0;
}
body,
button,
select,
input,
textarea {
	font-family: 'TT Interphases Pro Trial', sans-serif !important;
}
::selection {
	background: var(--yellow);
	color: var(--black);
}

::-webkit-scrollbar {
	width: 1.5px;
	height: 0;
}
::-webkit-scrollbar-track {
	background: var(--white);
}
::-webkit-scrollbar-thumb {
	background: var(--blue);
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
}
a {
	text-decoration: none !important;
	cursor: pointer;
	color: var(--black) !important;
}
a:hover,
a.active {
	color: var(--blue) !important;
}
ul {
	list-style-type: none !important;
	padding: 0;
}
input {
	outline: none !important;
	box-shadow: none !important;
	font-family: 'TT Interphases Pro Trial', sans-serif;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	appearance: textfield;
}
input[type='date'] {
	cursor: text !important;
}
textarea {
	resize: none !important;
}
button {
	outline: none !important;
	box-shadow: none !important;
	cursor: pointer;
}
.sidebar {
	touch-action: none;
	-ms-touch-action: none;
	overflow: hidden;
}
.highcharts-credits {
	display: none;
}

.container2 {
	max-width: 2000px;
	margin: 0 auto;
}
.hover-arrow-button:hover .hover-arrow path {
	fill: #4680ff;
}
.leaflet-top,
.leaflet-bottom {
	z-index: 800 !important;
}
@media (max-width: 8000px) and (min-width: 7000px) {
	.container2 {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
}
.gmnoprint > div > .gm-control-active,
.gmnoprint > div {
	border-radius: 0.5rem !important;
}
.gmnoprint > div > div {
	width: 100% !important;
	margin: 0 !important;
}

.loaderrr {
	animation: spinn 1s linear infinite;
}

@keyframes spinn {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.highcharts-container {
	padding: 10px;
	box-sizing: border-box;
}
.leaflet-container {
	background-color: #d5e8eb !important;
}
.leaflet-control-attribution.leaflet-control {
	display: none;
}
/* .leaflet-control-zoom {
  display: none;
} */
.custom-dashed-border {
	border-style: dashed;
	border-color: #b9bec7;
	border-image: repeating-linear-gradient(90deg, #b9bec7 0, #b9bec7 10px, transparent 10px, transparent 20px) 1;
}
.MapRegionforInspector .leaflet-popup-content-wrapper,
.MapRegionforInspector .leaflet-popup-tip {
	background: #2e2e2e !important;
	box-shadow: none;
}
.MapRegionforInspector .custom-popup .leaflet-popup-content {
	margin: 0 !important;
	padding: 0 !important;
}
